import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { AppSettings, AttendanceRegularization, Employee } from 'service/Models';
import ServerGateway from 'service/ServerGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import SettingsService from 'service/SettingsService';
import toast, { Toaster } from 'react-hot-toast';

export const Attendance = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [employee, setEmployee] = useState<Employee>();
    const [settings, setSettings] = useState<AppSettings>();
    const [month, setMonth] = useState<any>();
    const [attendance, setAttendance] = useState<any[]>([]);
    const [regularization, setRegularization] = useState<AttendanceRegularization>();
    const [regularizationReasons, setRegularizationReasons] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user.profile) {
            setEmployee(user.profile.profile);
            SettingsService.get()
                .then((data) => {
                    setSettings(data);
                });
        }
    }, [user]);

    useEffect(() => {
        if (month) {
            loadAttendance();
        }
    }, [month]);

    const loadAttendance = () => {
        setIsLoading(true);
        var month_data = moment(month + "-01")
        ServerGateway
            .getAttendance(employee?.id, moment(month_data).year(), (moment(month_data).month() + 1))
            .then((data) => {
                if (data.length > 0) {
                    data[0].attendances.forEach(a => {
                        a.showRegularization = false;
                    });
                    setAttendance(data[0].attendances);
                }
                else {
                    setAttendance([]);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const loadRegularizations = () => {
        var fromDate = moment(month + "-01").format("YYYY-MM-DD");
        var toDate = moment(fromDate).add(1, 'month').subtract(1, 'day').format("YYYY-MM-DD");
        ServerGateway
            .getRegularizations(employee?.id, "-1", fromDate, toDate)
            .then((data) => {
                if (data.entries.length > 0) {
                    let attendanceData = [ ...attendance ];
                    console.log(attendance)
                    data.entries.forEach(reg => {
                        let dayAttendance = attendanceData.find(a => a.date === reg.fromDate);
                        if(dayAttendance) {
                            dayAttendance.regularization = reg;
                        }
                    });
                    //setAttendance(attendanceData);
                }
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        setMonth(moment().format('YYYY-MM'));
    }, []);

    const handleMonthChange = (e) => {
        setMonth(e.target.value);
    }

    const getClassNameBasedOnStatus = (status) => {
        if (status === "Present") {
            return "badge bg-success"
        }
        else if (status === "Absent" || status === "LOP") {
            return "badge bg-danger"
        }
        else if (status === "Leave") {
            return "badge bg-warning"
        }
        else if (status === "WeeklyOff") {
            return "badge bg-primary"
        }
        return "badge bg-secondary";
    }

    const loadRegularizationReasons = () => {
        if (regularizationReasons.length > 0) {
            return;
        }
        ServerGateway
            .getRegularizationReasons()
            .then((data) => {
                setRegularizationReasons(data);
                if (data.length > 0) {
                    if (regularization) {
                        regularization.reasonId = data[0].id;
                        setRegularization({ ...regularization });
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const showRegularization = (a) => {
        loadRegularizationReasons();
        let model = new AttendanceRegularization();
        model.employeeId = a.employeeId;
        model.fromDate = a.date;
        model.toDate = a.date;
        model.loginTime = a.loginTime;
        model.logoutTime = a.logoutTime;
        model.remarks = "";
        model.oldStatus = a.status;
        model.newStatus = "Present";
        model.reasonId = 1;
        console.log(model);
        setRegularization(model);
        setAttendance(attendance.map(attendance =>
            attendance.date === a.date ? { ...attendance, showRegularization: true } : attendance
        ));
    }

    const requestRegularization = (a) => {
        ServerGateway
            .requestRegularization(regularization)
            .then((data) => {
                toast('Regularization request saved successfully', { position: 'bottom-center', className: 'btn bg-success' });
                setTimeout(() => loadAttendance(), 1000);
            })
            .catch(error => {
                console.log(error);
                toast('Something went wrong while requesting for regularization. Try again, if problem persists contact HR', { position: 'bottom-center', className: 'btn bg-danger' });
            });
    }

    const hideRegularization = (a) => {
        setAttendance(attendance.map(attendance =>
            attendance.date === a.date ? { ...attendance, showRegularization: false } : attendance
        ));
    }

    const setRegularizationStatus = (e) => {
        if (regularization) {
            regularization.newStatus = e.target.value;
            setRegularization({ ...regularization });
        }
    }

    const setRegularizationReason = (e) => {
        if (regularization) {
            regularization.reasonId = e.target.value;
            setRegularization({ ...regularization });
        }
    }

    const setLoginTime = (e) => {
        if (regularization) {
            regularization.loginTime = moment(regularization.fromDate).format("YYYY-MM-DD") +"T" + e.target.value + ":00";;
            setRegularization({ ...regularization });
        }
    }

    const setLogoutTime = (e) => {
        if (regularization) {
            console.log(e.target.value);
            regularization.logoutTime =  moment(regularization.fromDate).format("YYYY-MM-DD") +"T" + e.target.value + ":00";
            setRegularization({ ...regularization });
        }
    }

    const setRemarks = (e) => {
        if (regularization) {
            regularization.remarks = e.target.value;
            setRegularization({ ...regularization });
        }
    }


    return (
        <div className="page-attendance">
            <div className='container'>
                <div className='row' style={{ marginBottom: "10px" }}>
                    <NavLink to="/attendance/punch" className='btn bg-primary'>
                        <FontAwesomeIcon icon={faClock} style={{ marginRight: 10 }} />
                        Mark Attendance
                    </NavLink>
                </div>
                <div className='row'>
                    <input type="month" className="form-control" value={month} onChange={handleMonthChange} style={{ textAlign: "center" }}></input>
                </div>
                <div className='row' style={{ marginTop: "20px" }}>
                    {isLoading &&
                        <Loader />
                    }
                    {!isLoading &&
                        <>
                            <div><Toaster /></div>
                            {!attendance &&
                                <div>Attendance information not available</div>
                            }
                            {attendance &&
                                <div className="accordion" id="accordion">
                                    {attendance.map((a) => (
                                        <>
                                            {(moment().startOf('day').diff(a.date) > 0) &&
                                                <div className="accordion-item" key={moment(a.date).format("DDMMYYYY")}>
                                                    <h2 className="accordion-header" id={'ah-' + moment(a.date).format("DDMMYYYY")}>
                                                        <div className="accordion-button bg-dark collapsed" data-bs-toggle="collapse" data-bs-target={'#ad-' + moment(a.date).format("DDMMYYYY")} aria-expanded="false" aria-controls={'ad-' + moment(a.date).format("DDMMYYYY")}>
                                                            {moment(a.date).format("DD, dddd")}
                                                            <div className={getClassNameBasedOnStatus(a?.status)} style={{ float: "right" }}>{a?.status}</div>
                                                        </div>
                                                    </h2>
                                                    {(a?.status != "WeeklyOff") &&
                                                        <div className="accordion-collapse collapse" id={'ad-' + moment(a.date).format("DDMMYYYY")} aria-labelledby={'ah-' + moment(a.date).format("DDMMYYYY")} data-bs-parent="#accordion">
                                                            <div className="accordion-body">
                                                                <ul className="list-group list-group-flush">
                                                                    {(a?.status != "Leave") &&
                                                                        <>
                                                                            <li className="list-group-item">
                                                                                Login Time
                                                                                <div className="fw-bold">
                                                                                    {moment(a.loginTime).format("hh:mm A") + " - " + moment(a.logoutTime).format("hh:mm A")}
                                                                                </div>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                Status
                                                                                <div className="fw-bold">{a?.status}</div>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                Working hour
                                                                                <div className="fw-bold">
                                                                                    {moment.utc(a.workingHours * 60 * 60 * 1000).format("HH:mm")}
                                                                                </div>
                                                                            </li>
                                                                            {settings?.showDeduction &&
                                                                                <li className="list-group-item">
                                                                                    Deduction
                                                                                    <div className="fw-bold">
                                                                                        {moment.utc(a.deductionHours * 60 * 60 * 1000).format("HH:mm")}
                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                            {settings?.showOT &&
                                                                                <li className="list-group-item">
                                                                                    OT
                                                                                    <div className="fw-bold">
                                                                                        {moment.utc(a.otHours * 60 * 60 * 1000).format("HH:mm")}
                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                        </>
                                                                    }
                                                                    {(a?.status === "Leave") &&
                                                                        <>
                                                                            <li className="list-group-item">
                                                                                Leave Type
                                                                                <div className="fw-bold">
                                                                                    {a?.leaveType?.name}
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    }
                                                                    {settings?.requestAttendanceRegularization &&
                                                                        <li className="list-group-item">
                                                                            {!a.showRegularization &&
                                                                                <>
                                                                                    {a.regularization &&
                                                                                        <>
                                                                                            {a.regularization.approvalStatus == 1 &&
                                                                                                <div className='alert alert-secondary'>Regularization Request Pending</div>
                                                                                            }
                                                                                            {a.regularization.approvalStatus == 2 &&
                                                                                                <div className='alert alert-success'>Regularized (Request Approved)</div>
                                                                                            }
                                                                                            {a.regularization.approvalStatus == 3 &&
                                                                                                <div className='alert alert-danger'>Regularization Request Rejected</div>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                    {!a.regularization &&
                                                                                        <div className='btn btn-primary w-100' onClick={() => showRegularization(a)}>Request Regularization</div>
                                                                                    }
                                                                                </>
                                                                            }
                                                                            {a.showRegularization &&
                                                                                <div className='form list-group'>
                                                                                    <div className="row">
                                                                                        <div className="form-group col-md-6">
                                                                                            <label>Status</label>
                                                                                            <select className="form-control"
                                                                                                value={regularization?.newStatus}
                                                                                                onChange={e => setRegularizationStatus(e)}>
                                                                                                <option value="Present">Present</option>
                                                                                                <option value="LossOfPay">Absent</option>
                                                                                                <option value="HalfDay">Half Day</option>
                                                                                                <option value="WeeklyOff">Weekly Off</option>
                                                                                                <option value="Holiday">Holiday</option>
                                                                                                <option value="Leave">Leave</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="form-group col-md-6">
                                                                                            <label>Reason</label>
                                                                                            <select className="form-control"
                                                                                                value={regularization?.reasonId}
                                                                                                onChange={(e) => setRegularizationReason(e)}>
                                                                                                {regularizationReasons.map((reason) => (
                                                                                                    <option value={reason.id}>{reason.name}</option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="form-group col-md-6">
                                                                                            <label>Login Time</label>
                                                                                            <input type="Time"
                                                                                                className="form-control"
                                                                                                value={moment(regularization?.loginTime).format("HH:mm")}
                                                                                                onChange={e => setLoginTime(e)}></input>
                                                                                        </div>
                                                                                        <div className="form-group col-md-6">
                                                                                            <label>Logout Time</label>
                                                                                            <input type="Time"
                                                                                                className="form-control"
                                                                                                value={moment(regularization?.logoutTime).format("HH:mm")}
                                                                                                onChange={e => setLogoutTime(e)}></input>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>Notes</label>
                                                                                        <textarea
                                                                                            className="form-control"
                                                                                            rows={3}
                                                                                            value={regularization?.remarks}
                                                                                            onChange={e => setRemarks(e)}></textarea>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <button className="btn btn-danger btn-sm" onClick={() => hideRegularization(a)}>Cancel</button>
                                                                                        <button className="btn btn-primary floar-right" onClick={() => requestRegularization(a)} style={{ float: "right" }}>Request</button>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </li>
                                                                    }
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    }
                                                </div >
                                            }
                                        </>
                                    ))}
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div >
    );
}