import { string } from "prop-types"
import internal from "stream";


export interface Employee {
    fathersName: string,
    spouseName: string,
    pseudoName: string,
    maritalStatus: string,
    employeeNumber: string,
    currentAddress: string,
    permanentAddress: string,
    emergencyContactNumber: string,
    qualification: string,
    qualificationName: string,
    experience: string,
    designation: 1,
    designationName: string,
    internationalWorker: boolean,
    countryOfOrigin: string,
    passportNumber: string,
    passportValidFrom: string,
    passportValidUpto: string,
    joiningDate: string,
    panNumber: string,
    nameAsInPAN: string,
    aadhaarNumber: string,
    nameAsInAadhaar: string,
    uan: string,
    epfAccountNumber: string,
    ctc: number,
    salaryStructureId: string,
    monthlyCTC: string,
    isActive: boolean,
    bankAccountNumber: string,
    ifscCode: string,
    nameAsInBank: string,
    isPFOptedOut: boolean,
    isESIOptedOut: boolean,
    isRelieved: boolean,
    relievingDate: string,
    relievingReason: string,
    defaultPaymentType: string,
    shiftId: number,
    shiftName: string,
    holidayListId: number,
    holidayPlanName: string,
    weeklyOffPlan: string,
    leavePlanId: number,
    leavePlanName: string,
    mothersName: string,
    bankName: string,
    pfVolunteryContribution: number,
    esiAccountNumber: string,
    pfeKYCStatus: number,
    pfeNominationStatus: number,
    previousPFAccountNumber: string,
    dateOfExitFromPrevEmployment: string,
    schemeCertificateNumber: string,
    ppoNumber: string,
    isNewPFRegistration: boolean,
    allowAppLogin: boolean,
    id: number,
    code: string,
    type: number,
    category: number,
    name: string,
    nameLower: string,
    mobileNumber: string,
    contactPerson: string,
    searchName: string,
    gstin: string,
    pan: string,
    stateCode: number,
    stateName: string,
    email:  string,
    landlineNumber: string,
    billingAddress: string,
    shippingAddress: string,
    groupName: string,
    dob: string,
    gender: string
}

export interface Transaction {
    closingBalance: number;
    credit: number;
    debit: number;
    description: string;
    openingBalance: number;
    transactionRefNumber: number;
    transactionType: string;
    voucherId: number;
    voucherNumber: string;
    voucherType: string;
    transactionDate: string;
}

export interface BusinessSummaryModel {
    openingBalance: number;
    sales: number;
    creditNote: number;
    return: number;
    receipt: number;
    journal: number;
    business: number;
    closingBalance: number;
    newBalance: number;
    oldBalance: number;
    partyName: string;
    mobile: string;
    address: string;
    contact: string;
}

export interface PendingInvoices {
    voucherDate: string;
    paymentDate: string;
    voucherNumber: string;
    billAmount: number;
    paidAmount: number;
    pendingAmount: number;
    dueDays: number;
}

export interface SalesPerson {
    id: number;
    name: string;
    mobileNumber: string;
    isActive: boolean;
}

export interface VoucherSummary {
    numberOfInvoices: number;
    billAmount: number;
}

export interface DispatchDetails {
    dispatchedThrough: string,
    vehicleNumber: string,
    driverName: string,
    driverNumber: string
}

export interface AppSettings {
    showLeaves: boolean,
    showAttendance: boolean,
    showPayslips: boolean,
    markAttendance: boolean,
    showRegularization: boolean,
    showOT: boolean,
    showDeduction: boolean,    
    trackLocation: boolean,   
    takePicture: boolean,   
    enableGeofence: boolean,   
    requestAttendanceRegularization: boolean,
}

export class AttendanceRegularization {
    employeeId: number;
    fromDate: string;
    toDate: string;
    reasonId: number;
    loginTime: string;
    logoutTime: string;
    otHours: number;
    deductionHours: number;
    oldStatus: string;
    newStatus: string;
    leaveTypeId: string;
    remarks: string;
}